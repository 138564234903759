import { Image, SimpleGrid, VStack } from '@chakra-ui/react';
import { PageProps } from 'gatsby';
import React from 'react';

import { ScholarshipWinnerResponsePagedResults } from '../../../../apis';
import Confetti from '../../../../components/Confetti';
import DisclosuresAdapter from '../../../../components/DisclosuresAdapter';
import { ScholarshipRecipientForm } from '../../../../components/Forms/Scholarship';
import Layout from '../../../../components/Layout';
import LearnMoreLinks from '../../../../components/LearnMoreLinks';
import PageIdentifier from '../../../../components/PageIdentifier';
import { ScholarshipSearchResults } from '../../../../components/SearchResults';
import {
  FacebookIcon,
  TwitterIcon,
  WLButton,
  WLContainer,
  WLH2,
  WLH4,
  WLText,
} from '../../../../design-library';
import ScholarshipBanner from './ScholarshipBanner.svg';

interface ScholarshipRecipientsPageProps extends PageProps<undefined> {}
type SearchResults =
  | null
  | 'processing'
  | 'error'
  | {
      results: ScholarshipWinnerResponsePagedResults;
      setPage: (page: number) => void;
    };

const ScholarshipRecipientsPage: React.FC<ScholarshipRecipientsPageProps> = ({
  location,
  path,
}) => {
  const [results, setResults] = React.useState<SearchResults>(null);
  return (
    <Layout
      seo={{
        title: 'WoodmenLife Scholarships Recipients',
        description:
          'View all the recipients of WoodmenLife Focus Forward Scholarships.',
      }}
      currentPath={location.pathname}
    >
      <WLContainer width="FULL_WIDTH" position="relative">
        <Confetti />

        <WLContainer width="NARROW" paddingBottom={16} paddingX={2}>
          <Image alt="graduation hat" src={ScholarshipBanner as any} />
          <WLText>
            WoodmenLife is honored to present scholarships to these deserving
            members so they can further their education and pursue future
            opportunities.
          </WLText>
          <WLText as="i">
            — Denise M. McCauley, WoodmenLife President & CEO
          </WLText>

          <VStack>
            <WLText as="i">Share the excitement!</WLText>
            <SimpleGrid spacing={4} columns={{ base: 1, md: 2 }}>
              <WLButton
                href="https://www.facebook.com/sharer/sharer.php?kid_directed_site=0&sdk=joey&u=https%3A%2F%2Fwww.woodmenlife.org%2Fextras%2Fscholarships%2Fwinners.cfm&display=popup&ref=plugin&src=share_button"
                leftIcon={<FacebookIcon boxSize="2em" color="blue.700" />}
                variant="outline"
                colorScheme="blue"
              >
                Share
              </WLButton>
              <WLButton
                href="https://twitter.com/intent/tweet?original_referer=https%3A%2F%2Fwww.woodmenlife.org%2Fextras%2Fscholarships%2Frecipients%2F&ref_src=twsrc%5Etfw%7Ctwcamp%5Ebuttonembed%7Ctwterm%5Eshare%7Ctwgr%5E&text=WoodmenLife%20Scholarships%20Recipients&url=https%3A%2F%2Fwww.woodmenlife.org%2Fextras%2Fscholarships%2Fwinners.cfm&via=WoodmenLife"
                leftIcon={<TwitterIcon boxSize="2em" color="blue.700" />}
                variant="outline"
                colorScheme="blue"
              >
                Tweet
              </WLButton>
            </SimpleGrid>
          </VStack>
        </WLContainer>
        <WLH2 textAlign="center">The Scholarship Recipients</WLH2>
      </WLContainer>

      <WLContainer
        width="FULL_WIDTH"
        backgroundColor="blue.800"
        paddingY={4}
        paddingX={2}
      >
        <WLText as="i" color="white">
          Filter the recipients by year, state and dollar amount
        </WLText>
        <ScholarshipRecipientForm onStatusChange={setResults} />
      </WLContainer>
      <WLContainer width="NARROW" paddingY={8}>
        <ScholarshipSearchResults results={results} />
      </WLContainer>

      <WLContainer width="WIDE">
        <LearnMoreLinks path={path} />
      </WLContainer>
      <WLContainer width="WIDE" paddingY={4}>
        <DisclosuresAdapter>
          <WLH4>Disclosures</WLH4>
          <WLText>
            Purchase insurance and annuity products that meet your financial
            situation, needs and objectives. Never purchase insurance and
            annuity products for the sole purpose of qualifying for
            non-guaranteed membership extras.
          </WLText>
        </DisclosuresAdapter>
      </WLContainer>

      <WLContainer width="WIDE" align="start" paddingX={{ base: 3, xl: 0 }}>
        <PageIdentifier webNumber="WEB210" lastUpdated="7/20/2021" />
      </WLContainer>
    </Layout>
  );
};

export default ScholarshipRecipientsPage;
