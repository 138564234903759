import { Box } from '@chakra-ui/react';
import ConfettiGenerator from 'confetti-js';
import React from 'react';

interface ConfettiProps {}

const Confetti: React.FC<ConfettiProps> = () => {
  React.useLayoutEffect(() => {
    const confettiSettings = {
      target: 'confettiCanvas',
      max: '125',
      size: '1',
      animate: true,
      props: ['circle', 'square', 'line'],
      colors: [
        [0, 0, 0],
        [218, 165, 32],
      ],
      clock: '10',
    };
    const confetti = new ConfettiGenerator(confettiSettings);
    confetti.render();
  }, []);

  return (
    <Box
      id="confettiCanvas"
      as="canvas"
      position="absolute"
      left={0}
      bottom={0}
      zIndex={-1}
    />
  );
};

export default Confetti;
